




























































/* eslint-disable global-require */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

// eslint-disable-next-line
// @ts-ignore
import { TestimonialCard } from '@/client/components/_theme';

@Component({
  components: {
    TestimonialCard,
  },
})
export default class Testimonials extends Vue {
  testimonials1 = require('@/client/assets/images/landscape-4.jpg');
  sectionTestimonials1 = {
    testimonials1: require('@/client/assets/images/landscape-1.jpg'),
    testimonials2: require('@/client/assets/images/landscape-2.jpg'),
    testimonials3: require('@/client/assets/images/landscape-3.jpg'),
  };

  // eslint-disable-next-line class-methods-use-this
  sectionTestimonials(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
