export const discusContent = {
  header: {
    title: 'Landscape Design',
    subTitle: 'Modern Designs to Wow Your Community',
    text: `
      Custom landscape design is what we do best! Whether you have a vision of what you want, or you just need
      a landscape renovation, our landscape designers can assemble a one of a kind landscape design
      plan that reflects your outdoor dreams and incorporates your sense of style.
    `,
  },
  passionSections: [
    {
      title: 'Our Design Process',
      passions: [
        {
          title: 'On-Site Consultation',
          text: `
            When you hire us to bring a vision to life, one of our landscape designers will come out to your home for an
            in-depth and in-person consultation in preparation for your custom design. During this time, the designer will
            be asking you questions to get a true sense of your goals, style and vision as well as to establish the overall
            budget for your project.
          `,
          cardText: 'Consultation',
          buttonText: 'Get Started',
          routerConfig: {
            title: 'Landscape Design',
            subtype: 'landscape-design',
            text: `
              When you hire us to bring a vision to life, one of our landscape designers will come out to your home for an
              in-depth and in-person consultation in preparation for your custom design. During this time, the designer will
              be asking you questions to get a true sense of your goals, style and vision as well as to establish the overall
              budget for your project.
            `,
          },
        },
        {
          title: 'Site Survey',
          text: `
            During the latter part of the in-person consultation, our designer will perform an outdoor site survey of your property
            and the proposed space to ensure that all plans are attainable given the current layout and condition of the garden. They
            will plan to ensure proper conditions which include: available natural sunlight, land grades, soil type, obstructions and
            existing irrigation and drainage systems.
          `,
          cardText: 'Survey',
          buttonText: 'Get Started',
          routerConfig: {
            title: 'Landscape Design',
            subtype: 'landscape-design',
            text: `
              When you hire us to bring a vision to life, one of our landscape designers will come out to your home for an
              in-depth and in-person consultation in preparation for your custom design. During this time, the designer will
              be asking you questions to get a true sense of your goals, style and vision as well as to establish the overall
              budget for your project.
            `,
          },
        },
        {
          title: 'Digital Design & Presentation',
          text: `
            Once the digital design has been completed, our designer will contact you to set up an in-person presentation.
            This design presentation typically takes one hour. Throughout this presentation, our clients are encouraged to
            ask any questions and provide input. Changes to the design can be made in real time during this presentation.
            By the end of our presentation, the design should be a reflection of your goals and vision for the final product
            and you should have a landscape design you are thrilled about.
          `,
          cardText: 'Design & Presentation',
          buttonText: 'Get Started',
          routerConfig: {
            title: 'Landscape Design',
            subtype: 'landscape-design',
            text: `
              When you hire us to bring a vision to life, one of our landscape designers will come out to your home for an
              in-depth and in-person consultation in preparation for your custom design. During this time, the designer will
              be asking you questions to get a true sense of your goals, style and vision as well as to establish the overall
              budget for your project.
            `,
          },
        },
      ],
      fullImageBgSection: {
        title: 'Custom Landscape Design',
        text: `
          We will work tirelessly to ensure that you receive the lawn you always dreamed of. Our staff are available 7 days a week
          for any questions regarding a custom project.
        `,
      },
    },
  ],
} as const;

export const aquascapeContent = {
  header: {
    title: 'Landscape Installation',
    subTitle: 'From planting to stonework, we have you covered',
    text: `
      Modern Landscapes offers expert landscaping installation & construction services in North TX and the nearby areas.
      If your lawn is starting to look a little aged and tired, we can scratch with a new landscape design, our team is
      ready and able to work with you to turn your landscaping ideas into reality.
    `,
  },
  passionSections: [
    {
      title: 'Some of our services include',
      passions: [
        {
          title: 'Tree & Bush Planting',
          text: `
            A garden bed wouldn’t be complete without adding colorful ornamental plants and a layer of fresh mulch. When you count on Modern Landscapes
            to get your new landscaping plants installed, you can always count on receiving superior plants from a local commercial
            nursery. We have access to a wide variety of Texas native trees, shrubs, ornamental grasses, annuals, perennials and more.
          `,
          cardText: 'Tree & Bush',
          buttonText: 'Tree & Bush',
          routerConfig: {
            title: 'Tree & Bush Planting Services',
            subtype: 'landscape-installation',
            text: `
              A garden bed wouldn’t be complete without adding colorful ornamental plants and a layer of fresh mulch. When you count on Modern Landscapes
              to get your new landscaping plants installed, you can always count on receiving superior plants from a local commercial
              nursery. We have access to a wide variety of Texas native trees, shrubs, ornamental grasses, annuals, perennials and more.
            `,
          },
        },
        {
          title: 'Flower Bed Stone and Brick Work',
          text: `
            Installing borders around flower beds or garden beds is the best way to prevent erosion and protect plantings from invading weeds,
            grasses and pests. We install stone and steel borders of all kinds. The best looking and longest lasting flower bed borders are made
            of stone. Our stone masonry team can install flower bed borders made of elegant natural stones and colored mortars that are sure to 
            enhance the look of your landscaping and complement the architecture of your home or business.
          `,
          cardText: 'Flower Bed',
          buttonText: 'Flower Bed',
          routerConfig: {
            title: 'Flower Bed Stone and Brick Work',
            subtype: 'landscape-installation',
            text: 'Our stone masonry team can install flower bed borders made of elegant natural stones and colored mortars that are sure to enhance the look of your landscaping and complement the architecture of your home or business.',
          },
        },
        {
          title: 'Fresh Sod',
          text: `
            Laying new sod grass is the fastest and easiest way to renew a worn out lawn. In a matter of hours, a muddy weed infested yard can be
            transformed in to a beautiful green lawn.
          `,
          cardText: 'Fresh Sod',
          buttonText: 'Sod Laying',
          routerConfig: {
            title: 'Fresh Sod Laying Services',
            subtype: 'landscape-installation',
            text: `
              Laying new sod grass is the fastest and easiest way to renew a worn out lawn. In a matter of hours, a muddy weed infested yard can be
              transformed in to a beautiful green lawn.
            `,
          },
        },
      ],
      fullImageBgSection: {
        title: 'We take pride in the lawns we create',
        text: `
          Countless hours go into the planning and preparation for for any scape. You have to make sure that
          the proper sod used based on location and wheather, and that the right balance of plants are selected
          for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
          in it's own ways. It is our mission to help you recreate these beautiful scenes at home.
        `,
      },
    },
  ],
} as const;

export const microscapeContent = {
  header: {
    title: 'Microscapes',
    subTitle: 'The Art of Aquascaping in Small Spaces',
    text: `
      Aquascaping in itself can be incredibly challenging yet rewarding. Aquascaping for small
      spaces in a whole new challenge in itself. Each scape has to be carefully planned to be almost
      completely self sustained. These pieces can make a dramatic statement in smaller spaces.
    `,
  },
  passionSections: [
    {
      title: 'Some of our kits include',
      passions: [
        {
          title: 'Nature Bowls',
          text: `
            Terrariums come in all tastes, the possibilities of how a terrarium can look are endless! The beauty of these
            small ecosystems is that they are mostly self sustained after being set up, meaning you can enjoy them as a nice
            desktop or bookshelf decoration for years to come. Literally whatever look you are going for, we can achieve it.
          `,
          cardText: 'Nature Bowl',
          buttonText: 'Bowls',
          routerConfig: {
            title: 'Nature Bowls',
            subtype: 'bowls',
            text: 'Microscapes for those who love nature',
          },
        },
        {
          title: 'Desktop Ponds',
          text: `
            Desktop ponds are some of the most beautiful decorations you can have for small spaces. They are definite
            conversation pieces and will have you and your guests staring in awe. These setups are very small and typically
            low maintenence. They are perfect for those who want to bring the delights of a pond to their desktops.
          `,
          cardText: 'Micro Ponds',
          buttonText: 'Desktop Ponds',
          routerConfig: {
            title: 'Desktop Pond',
            subtype: 'ponds',
            text: 'A small pond to enjoy in any area',
          },
        },
        {
          title: 'Slice of Nature Setups',
          text: `
            Imagine having a literal slice of nature sitting in one of our rooms. With advance aquascaping techniques this can be
            achieved. Whether it be a creakbed scene, or walk through the forest we can capture almost any piece of nature you desire.
          `,
          cardText: 'Natural Scenery',
          buttonText: 'Nature Setups',
          routerConfig: {
            title: 'Natural Microscape',
            subtype: 'natural',
            text: 'Microscapes for those who love nature',
          },
        },
      ],
      fullImageBgSection: {
        title: 'We believe that every aquascape both large and small is a work of art.',
        text: `
          Countless hours go into the planning and preparation for for any scape. You have to make sure that
          all proper nutrients and substrate are used, and that the right balance of plants and rocks are selected
          for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
          in it's own ways. We take pride in every one of our aquascapes and we want to help you recreate these beautiful
          scenes at home.
        `,
      },
    },
  ],
} as const;

export const shrimpContent = {
  header: {
    title: 'Basic Lawn Care',
    subTitle: 'Ensuring your lawn always looks profesionally cared for',
    text: `
      As homeowners and renters, many of us pride ourselves in having a beautiful lawn. One that makes your house stand out
      amongst your neighbors. At Modern Lawnscapes it is our missions to ensure that your lawn always remains healthy, show
      ready, and bold.
    `,
  },
  passionSections: [
    {
      title: 'Our Basic Lawn Services Include',
      passions: [
        {
          title: 'Scheduled Mowing/Weed-Eating',
          text: `
            We get it, sometimes mowing the lawn just just not worth it, albeit the satisfaction at the end. We understand
            completedly and will be there to ensure your lawn is always maintained on time, so you can get the satisfaction
            without dedicating the hard work and time.
          `,
          cardText: 'Mow & Weed-Eat',
          buttonText: 'Basic Care',
          routerConfig: {
            title: 'Scheduled Maintenance',
            subtype: 'basic-maintenance',
            text: `
              We get it, sometimes mowing the lawn just just not worth it, albeit the satisfaction at the end. We understand
              completedly and will be there to ensure your lawn is always maintained on time, so you can get the satisfaction
              without dedicating the hard work and time.
            `,
          },
        },
        {
          title: 'Tree and Bush Trimming',
          text: `
            Our team will handle all tree and bush trimming so your lawn always looks prestine. Never worry about the hazards
            of climing and trimming your trees and free up time to focus on more important tasks.
          `,
          cardText: 'Tree & Bush',
          buttonText: 'Tree & Bush',
          routerConfig: {
            title: 'Trimming Service',
            subtype: 'tree-services',
            text: `
              Our team will handle all tree and bush trimming so your lawn always looks prestine. Never worry about the hazards
              of climing and trimming your trees and free up time to focus on more important tasks.
            `,
          },
        },
      ],
      fullImageBgSection: {
        title: 'Scheduled Maintenance',
        text: `
          We understand being busy. Let us handle the time consuming part of your weekends so you can focus on the important tasks
        `,
      },
    },
    {
      title: 'We also provide customized service plans for specialty plants',
      passions: [
        {
          title: 'Custom Care Plan',
          text: `
            We understand that not all plants are created equal. Some require much more specialty care and maintenance than others.
            You can rest easy as our staff are incredibly knowledgable and well trained in specialty care services. We will develop
            a care plan that will ensure your specialty plants live and thrive.
          `,
          cardText: 'Specialty Care Plan',
          buttonText: 'Specialty Care',
          routerConfig: {
            title: 'Specialty Care Plan',
            subtype: 'cherry',
            text: 'A to SSS Grade',
          },
        },
      ],
      fullImageBgSection: {
        title: 'Speicalty Maintenance',
        text: `
          Our staff has a vast knowledge of specialty plants. If you have exotic plants, we have you covered with maintenance.
        `,
      },
    },
  ],
} as const;

export const aboutContent = {
  header: {
    title: 'Our Story',
    subTitle: 'What started as a hobby and passion turned into a business',
    text: `
      Modern Landscaping specializes in landscape design and landscaping installation services in North Texas. As a small company,
      we are personal, reliable and focused on what we do best - residential landscaping services. From our landscape design team
      to our installation crew, we offer a complete landscaping solution with unbeatable quality and customer service.
    `,
  },
  passionSections: [
    {
      title: 'Some of our passions include',
      passions: [
        {
          title: 'Basic Lawn Care',
          text: `
            As homeowners and renters, many of us pride ourselves in having a beautiful lawn. One that makes your house stand out
            amongst your neighbors. At Modern Lawnscapes it is our missions to ensure that your lawn always remains healthy, show
            ready, and bold.
          `,
          cardText: 'Lawn Care',
          buttonText: 'Learn More',
          url: '/specialties/basic-lawn',
        },
        {
          title: 'Landscape Design',
          text: `
            Custom landscape design is what we do best! Whether you have a vision of what you want, or you just need
            a landscape renovation, our landscape designers can assemble a one of a kind landscape design
            plan that reflects your outdoor dreams and incorporates your sense of style.
          `,
          cardText: 'Landscape Design',
          buttonText: 'Learn More',
          url: '/specialties/landscape-design',
        },
        {
          title: 'Landscape Installation',
          text: `
            Modern Landscapes offers expert landscaping installation & construction services in North TX and the nearby areas.
            If your lawn is starting to look a little aged and tired, we can start scratch with a new landscape design, our team is
            ready and able to work with you to turn your landscaping ideas into reality.
          `,
          cardText: 'Landscape Installation',
          buttonText: 'Learn More',
          url: '/specialties/landscape-isntallation',
        },
      ],
      fullImageBgSection: {
        title: 'We take pride in everything that we do',
        text: `
          So much so that if you are not satisfied with your purchase we will give you a full refund, no questions asked.
        `,
      },
    },
  ],
} as const;

export const typesLookup: any = {
  'basic lawn': {
    name: 'Basic Lawn',
    path: '/specialties/basic-lawn',
    values: [
      'Mowing',
      'Trimming',
      'Specialty Care',
    ],
  },
  'landscape design': {
    name: 'Landscape Design',
    path: '/specialties/landscape-design',
    values: [
      'Custom Landscape',
    ],
  },
  'landscape installation': {
    name: 'Landscape Installation',
    path: '/specialties/landscape-installation',
    values: [
      'Sod Installation',
      'Tree Planting',
      'Bush Planting',
      'Custom Walkway',
      'Garden Beds',
    ],
  },
} as const;

export const specialtyLookup: any = {
  mowing: {
    title: 'Scheduled Mowing/Weed-Eating',
    subtitle: `
      We get it, sometimes mowing the lawn just just not worth it, albeit the satisfaction at the end. We understand
      completedly and will be there to ensure your lawn is always maintained on time, so you can get the satisfaction
      without dedicating the hard work and time.
    `,
    type: 'mow',
    specialty: 'basic lawn',
    price: 'Starting at $30',
  },
  trimming: {
    title: 'Tree & Bush Trimming',
    subtitle: `
      Our team will handle all tree and bush trimming so your lawn always looks prestine. Never worry about the hazards
      of climing and trimming your trees and free up time to focus on more important tasks.
    `,
    type: 'trimming',
    specialty: 'basic lawn',
    price: 'Starting at $70',
  },
  'specialty care': {
    title: 'Specialty Lawn Care',
    subtitle: `
      You can rest easy as our staff are incredibly knowledgable and well trained in specialty care services. We will develop
      a care plan that will ensure your specialty plants live and thrive.
    `,
    type: 'specialty',
    specialty: 'basic lawn',
    price: 'Get in touch so we can get to the details!',
  },
  'custom landscape': {
    title: 'Custom Landscape Design',
    subtitle: `
      Custom landscape design is what we do best! Whether you have a vision of what you want, or you just need
      a landscape renovation, our landscape designers can assemble a one of a kind landscape design
      plan that reflects your outdoor dreams and incorporates your sense of style.
    `,
    specialty: 'landscape design',
    price: 'Get in touch for details!',
  },
  'sod installation': {
    title: 'Sod Installation',
    subtitle: `
      Give your tired lawn a makeover in as little as a few hours with some new sod. Depending on the area,
      we may be able to service same day!
    `,
    specialty: 'landscape installation',
    price: 'Starting at $300',
  },
  'tree planting': {
    title: 'Tree and Shrub Planting',
    subtitle: `
      Create a dramatic new look with your lawn by adding some new trees and decorative bushes
    `,
    specialty: 'landscape installation',
    price: 'Starting at $1000',
  },
  'bush planting': {
    title: 'Tree and Shrub Planting',
    subtitle: `
      Create a dramatic new look with your lawn by adding some new trees and decorative bushes
    `,
    specialty: 'landscape installation',
    price: 'Starting at $1000',
  },
  'garden beds': {
    title: 'Garden and Flower Beds',
    subtitle: `
      Whether you would like to grow fresh veggies, or simply have a beautifully designed flower bed, we can make it happen.
    `,
    specialty: 'landscape installation',
    price: 'Starting at $300',
  },
  'custom walkway': {
    title: 'Custom Garden Paths and Walkways',
    subtitle: `
      Make anyone walking to your front door impressed with the detail of your custom stone pathways.
    `,
    specialty: 'landscape installation',
    price: 'Starting at $2000',
  },
};

export const allContent = {
  landing: {},
  about: aboutContent,
  aquascapes: aquascapeContent,
  discus: discusContent,
  microscapes: microscapeContent,
  shrimp: shrimpContent,
} as const;
